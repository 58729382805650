<template>
  <div class="page">
    <el-upload ref="upload" action :before-upload="upload" :show-file-list="false" accept=".xlsx, .xls">
      <el-button size="mini" type="primary">导入Excel</el-button>
    </el-upload>
    <div></div>
  </div>
</template>

<script>
// import * as echarts from 'echarts'
import { read, utils } from 'xlsx'
export default {
  name: 'HomeView',
  data () {
    return {
      orderData: [],
      goodsData: []
    }
  },
  methods: {
    init (orderData, goodsData) {
      console.log(orderData)
      console.log(goodsData)
      // this.orderData = orderData
      // this.goodsData = goodsData
    },
    upload (file, fileList) {
      this.loading = this.$loading({
        lock: true,
        text: '拼命加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      })
      const files = { 0: file }
      this.readExcel(files)
    },
    readExcel (files) {
      if (files.length <= 0) {
        this.loading.close()
        return
      }
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.loading.close()
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return
      }
      const fileReader = new FileReader()
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result
          const workbook = read(data, {
            type: 'binary'
          })
          const orderData = utils.sheet_to_json(workbook.Sheets['订单列表'])
          const goodsData = utils.sheet_to_json(workbook.Sheets['商品列表'])
          this.init(orderData, goodsData)
          this.loading.close()
        } catch (e) {
          this.loading.close()
          return false
        }
      }
      fileReader.readAsBinaryString(files[0])
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
